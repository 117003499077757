import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import pricesImage from "../images/prices.png";
import mobilePrices from "../images/mobilePrices.png";
import webPrices from "../images/webPrices.png";

function AboutPage() {

  // const isSmallScreen = window.innerWidth < 500;

  return (
    <Layout>
      <SEO
        title="Prices"
        keywords={[`order`, `design`, `mobile`, `prices`, `development`, `software`, `fair`, `personal`]}
      />

      <section className="flex flex-col md:flex-col items-center">

        <h2 className="text-2xl text-center font-bold">
          Website in a few days, apps in about a week.
        </h2>

        <div style={{ height: 3 }} />

        <h2 className="text-xl text-center">
          If you need {" "}
          <Link
            to="/restaurants"
            className="font-bold underline text-gray-900"
          >
            a similar feature set
          </Link>
          , your own website can be finished in a few days and the apps accepted in 5 days or so.
        </h2>

        <div style={{ height: 5 }} />

        <img
          src={pricesImage}
          className="block mx-auto"
          alt="web prices"
        />


        <div style={{ height: 3 }} />

        <Link
          to="/restaurants"
        >
          <h2 className="bg-yellow-400 leading-loose inline-block text-2xl p-2 text-center">
            Get started {" "}
            <Link
              to="/contact"
              className="font-bold underline text-gray-900"
            >
              here
          </Link>
          .
          </h2>
        </Link>


      </section>
    </Layout>
  );
}

export default AboutPage;
